<template>
  <transition>
    <FanArtGrid :fan-arts="featuredVisuals" />
  </transition>
</template>

<script lang="ts" setup>
import { getHomeFeaturedVisuals } from '@/shared/actions/characters';
import FanArtGrid from '@/shared/components/fan-art-grid.vue';

const featuredVisuals = ref<any[]>([]);
const featuredVisLoading = ref(false);

const fetchFeaturedVisuals = async () => {
  featuredVisLoading.value = true;

  const response = await getHomeFeaturedVisuals();
  featuredVisuals.value = response.value.results
    .filter((i: any) => !i.is_nsfw)
    .slice(0, 6)
    .map(({ image, character }: any) => ({
      visual_url: image.image,
      character,
      image,
    }));

  featuredVisLoading.value = false;
};

const router = useRouter();

const request = () => router.push('/signup');

onMounted(() => {
  fetchFeaturedVisuals();
});
</script>

<style lang="sass" scoped></style>
